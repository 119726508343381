// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import Grid from "@material-ui/core/Grid";
import {Button, FormControlLabel, Checkbox} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import {Field, Form, Formik} from "formik";
import * as Yup from "yup";

import ErrorMessage from "../../components/100Include/ErrorMessage";
import moment from "moment";
import {apiClient} from "../../Api/_ApiClient";
import { apiAuction } from "../../Api/_ApiAuction";
import {addMessage} from "../../Redux/Action/messageAction";
import {apiUsers} from "../../Api/_ApiUsers";

class ClientUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            client: {
                client_id: '',
                display_name: '',
                email: '',
                wechat_id: '',
                password: '',
                re_password: '',
                active: false,
                phone: '',
                credit: '',
                max_credit: '',
                client_platforms: [],
                power_bid_platforms: [],
                user: {
                    user_hash: '',
                },
            },
            platforms: [],
            openChangePwdDialog: false,
            roleValidateMsg: null
        };
    }

    componentDidMount() {
        this.getPlatforms();
        if(this.props.id) {
            this.setState({isEdit: true})
            this.getClientDetail();
        } else {
            const { t } = this.props;
            const userItemBreadcrumb = [{
                title: t("ClientManagement:addClient"),
                link: null
            }];
            this.props.setBreadcrumbP({breadcrumbData: userItemBreadcrumb});
        }
    }

    getClientDetail = () => {
        const params = {
            '$expand': 'user,client_platforms',
        };
        apiClient.getClientDetail(this.props.id, params).then(obj => {
            if(obj && obj.status === 200) {
                if(obj.data) {
                    const client = obj.data;
                    const userItemBreadcrumb = [{
                        title: `Edit User [${client.display_name}]`,
                        link: null
                    }];
                    this.props.setBreadcrumbP({breadcrumbData: userItemBreadcrumb});
                    this.setState({client: client})
                }
            }
        })
    }

    getPlatforms = () => {
        const params = {};
        apiAuction.getSuppliers(params).then(obj => {
            if(obj && obj.status === 200) {
                if(obj.data) {
                    this.setState({ platforms: obj.data });
                }
            } else {
                console.log(obj.error);
            }
        });
    }

    // BUTTON FUNCTION
    cancel = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/client-management?previous=true');
    }

    eCb = (obj) => {
        console.log("eCb : ", obj);
    }

    _addClientAsync = (value) => {
        const { i18n } = this.props;
        if(this.weChatIdUserValid(value.wechat_id, value.email)) {
            let regex=/^\s+|\s+$/g;
            let addClientBody = {
                "display_name": value.display_name,
                "email": value.email.replace(regex, ''),
                "phone": value.phone,
                "wechat_id": value.wechat_id,
                "credit": +value.credit,
                "max_credit": +value.max_credit,
                "active": value.active,
                "password": value.password,
                client_platforms: value.client_platforms,
                power_bid_platforms: value.power_bid_platforms,
            }

            return apiClient.addClient(addClientBody).then(obj => {
                if (obj.status === 201) {
                    const updateUserBody = {
                        user_hash: value.user.user_hash,
                    };
                    return apiUsers.updateUser(obj.data.user, updateUserBody).then(obj2 => {
                        if (obj2.status === 200) {
                            const msgDsp = {
                                messageSnackbar: 'Add client successfully',
                                variantSnackbar: 'success',
                                key: new Date().getTime(),
                            };
                            //Add msg
                            this.props.addMessageP(msgDsp);
                            this.props.history.push('/' + i18n.language + '/client-management?previous=true')
                        } else {
                            const error = obj.data && obj.data.error ? obj.data.error : 'Update user failed';
                            throw new Error(error);
                        }
                    });
                } else {
                    const error = obj.data && obj.data.error ? obj.data.error : 'Update client failed';
                    throw new Error(error);
                }
            }).catch(error => {
                const msgDsp = {
                    messageSnackbar: error.message,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
            });
        }
    }

    _updateClientAsync = (value) => {
        const { i18n } = this.props;
        const { client } = this.state;
        const { ...clientBody } = client;
        if (client.client_id && this.weChatIdUserValid(value.wechat_id, value.email)) {
            let regex = /^\s+|\s+$/g;
            let updateClientBody = {
                ...clientBody,
                "display_name": value.display_name,
                "email": value.email.replace(regex, ''),
                "phone": value.phone,
                "wechat_id": value.wechat_id,
                "credit": +value.credit,
                "max_credit": +value.max_credit,
                "active": value.active,
                "password": value.password,
                client_platforms: value.client_platforms,
                power_bid_platforms: value.power_bid_platforms,
                user: {
                    ...client.user,
                    user_hash: value.user.user_hash,
                },
            };
            return apiClient.updateClientWithPost(client.client_id, updateClientBody).then(obj => {
                if (obj.status === 200) {
                    const msgDsp = {
                        messageSnackbar: 'Update client successfully',
                        variantSnackbar: 'success',
                        key: new Date().getTime(),
                    };
                    //Add msg
                    this.props.addMessageP(msgDsp);
                    this.props.history.push('/' + i18n.language + '/client-management?previous=true')
                } else {
                    const error = obj.data && obj.data.error ? obj.data.error : 'Update user failed';
                    throw new Error(error);
                }
            })
            .catch(error => {
                const msgDsp = {
                    messageSnackbar: error.message,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
            });
        }
    }

    _submitClientForm = (value) => {
        if(this.state.isEdit) {
            this._updateClientAsync(value);
        } else {
            this._addClientAsync(value);
        }
    }

    weChatIdUserValid = (wechatId, email) => {
        return (wechatId && !email) || (!wechatId && email)
    }

    // CHANGE PASSWORD
    openChangePwd = () => {
        this.setState({ openChangePwdDialog: true });
    };
    handleCloseChangePwd = () => {
        this.setState({ openChangePwdDialog: false });
    };

    _submitChangePwdForm = (value) => {
        const body = {"new_password": value.new_password}
        // Change password
        apiUsers.changePassword(this.state.client.user.username, body).then(obj => {
            if (obj.status === 204) {
                const msgDsp = {
                    messageSnackbar: 'Change password successfully.',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
            } else {
                const msg = obj && obj.data.error ? obj.data.error : 'Cannot change user password';
                const msgDsp = {
                    messageSnackbar: msg,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
            }
        });
    }

    // FORM CONFIG
    formConfiguration = ({ values, errors, touched, handleChange }) => {
        const { t, id } = this.props;
        const { client, platforms } = this.state;

        return values && (
            <Form id="userForm" className="full-width" autoComplete="nope">
                <Grid container xm={12} alignItems="center">
                    {this.state.MessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={this.state.MessageContent}
                        />
                    </Grid>
                    }

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            {t("ClientManagement:userName")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="display_name" type="text" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.display_name && touched.display_name ? <ErrorMessage message={errors.display_name} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={12} className="form-item">
                            <span>Please register with WeChat or Email:</span>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            <span className="pl30">{t("ClientManagement:weChat")}</span>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="wechat_id" type="text" placeholder="" maxLength="100" autoComplete="off"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.wechat_id && touched.wechat_id ? (<span className="pl20"><ErrorMessage message={errors.wechat_id} /></span>) : null}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            <span className="pl30">&nbsp;</span>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <span>Or</span>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            <span className="pl30">{t("ClientManagement:email")}</span>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="email" type="text" placeholder="" maxLength="100" autoComplete="off"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.email && touched.email ? <ErrorMessage message={errors.email} /> : null}
                            </Grid>
                            <Grid item xs={12}>
                                {((touched.email || touched.wechat_id) && (!values.email && !values.wechat_id)) || (values.email.length > 0 && values.wechat_id.length > 0)
                                    ? <ErrorMessage message="Please input only Wechat ID or Email" /> : null}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt30" >
                        <Grid item xs={2} className="form-item">
                            <span className="pl30">{t("ClientManagement:password")}</span>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                { values.isEdit ?
                                    <Button type="button" className="second-button" onClick={this.openChangePwd}>Set Password</Button>
                                    : <Field name="password" type="password" placeholder="" maxLength="100"/>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                { values.isEdit ?
                                    null
                                    : (errors.password && touched.password ? <ErrorMessage message={errors.password} /> : t("Common:Form.validator.required"))
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {!values.isEdit &&
                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            <span className="pl30">{t("ClientManagement:rePassword")}</span>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="re_password" type="password" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.re_password && touched.re_password ? <ErrorMessage message={errors.re_password} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>
                    }

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20" >
                        <Grid item xs={2} className="form-item">
                            <span>{t("ClientManagement:phone")}</span>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="phone" type="text" placeholder=""/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.phone && touched.phone ? <ErrorMessage message={errors.phone} /> : null}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20" >
                        <Grid item xs={2} className="form-item">
                            <span>{t("ClientManagement:active")}</span>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <FormControlLabel
                                name="active"
                                checked={values.active}
                                onChange={handleChange}
                                control={<Checkbox />}
                                label=""
                            />
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20" >
                        <Grid item xs={2} className="form-item">
                            <span>{t("ClientManagement:creditBalance")}</span>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="credit" type="text" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.credit && touched.credit ? <ErrorMessage message={errors.credit} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            <span>{t("ClientManagement:maxCredit")}</span>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="max_credit" type="text" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.max_credit && touched.max_credit ? <ErrorMessage message={errors.max_credit} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={2} className="form-item">
                            <span>{t("ClientManagement:platforms")}</span>
                        </Grid>
                        <Grid item xs={10}>
                            <FormControl className='full-width'>
                                <Field name="client_platforms">
                                    {({ field, form, meta }) => (
                                        <Select
                                            multiple
                                            disableUnderline
                                            name="client_platforms"
                                            value={field.value.map(({ platform }) => platform)}
                                            onChange={e => {
                                                const selectedPlatformIds = e.target.value;
                                                const formattedValue = selectedPlatformIds.map(platformId => {
                                                    const existingClientPlatform = client.client_platforms.find(client_platform => client_platform.platform === platformId);
                                                    const clientPlatformFieldValue = field.value.find(client_platform => client_platform.platform === platformId);
                                                    return existingClientPlatform ? {
                                                        ...existingClientPlatform,
                                                        extended_bid: clientPlatformFieldValue ? clientPlatformFieldValue.extended_bid : false,
                                                    } : { platform: platformId, extended_bid: false };
                                                });
                                                e.target.value = formattedValue;
                                                handleChange('power_bid_platforms')({
                                                    ...e,
                                                    target: {
                                                        name: 'power_bid_platforms',
                                                        value: values.power_bid_platforms.filter(platformId => selectedPlatformIds.includes(platformId)),
                                                    }
                                                });
                                                return field.onChange(e);
                                            }}
                                            classes={{ root: 'custom-select select-multiple-checkbox' }}
                                            input={<Input className="select-multiple-checkbox-input" />}
                                            renderValue={selectedValues => selectedValues.map(platformId => {
                                                const selectedPlatform = platforms.find(({ supplier_id }) => supplier_id === platformId);
                                                return selectedPlatform ? selectedPlatform.name : '';
                                            }).join(', ')}
                                        >
                                            {platforms.map(platform => (
                                                <MenuItem key={platform.supplier_id} value={platform.supplier_id}>
                                                    <Checkbox checked={!!field.value.find(client_platform => client_platform.platform === platform.supplier_id)} />
                                                    <ListItemText primary={platform.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                </Field>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                        <Grid item xs={2} className="form-item">
                            <span>{t("ClientManagement:powerBid")}</span>
                        </Grid>
                        <Grid item xs={10}>
                            <FormControl className='full-width'>
                                <Field name="power_bid_platforms">
                                    {({ field, form, meta }) => {
                                        return (
                                        <Select
                                            multiple
                                            disableUnderline
                                            name="power_bid_platforms"
                                            value={field.value}
                                            onChange={field.onChange}
                                            classes={{ root: 'custom-select select-multiple-checkbox' }}
                                            input={<Input className="select-multiple-checkbox-input" />}
                                            renderValue={selectedValues => selectedValues.map(platformId => {
                                                const selectedPlatform = platforms.find(({ supplier_id }) => supplier_id === platformId);
                                                return selectedPlatform ? selectedPlatform.name : '';
                                            }).join(', ')}
                                        >
                                            {values.client_platforms.map(clientPlatform => {
                                                const platform = platforms.find(({ supplier_id }) => supplier_id === clientPlatform.platform);
                                                return platform ? (
                                                    <MenuItem key={platform.supplier_id} value={platform.supplier_id}>
                                                        <Checkbox checked={!!field.value.find(platformId => platformId === platform.supplier_id)} />
                                                        <ListItemText primary={platform.name} />
                                                    </MenuItem>
                                                ) : null;
                                            })}
                                        </Select>
                                    )}}
                                </Field>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                        <Grid item xs={2} className="form-item">
                            <span>{t("ClientManagement:extendedBid")}</span>
                        </Grid>
                        <Grid item xs={10}>
                            <FormControl className='full-width'>
                                <Field name="client_platforms">
                                    {({ field, form, meta }) => (
                                        <Select
                                            multiple
                                            disableUnderline
                                            name="client_platforms"
                                            value={field.value.filter(p => p.extended_bid).map(({ platform }) => platform)}
                                            onChange={e => {
                                                const selectedPlatformIds = e.target.value;
                                                const formattedValue = field.value.map(clientPlatform => ({
                                                    ...clientPlatform,
                                                    extended_bid: selectedPlatformIds.includes(clientPlatform.platform),
                                                }));
                                                e.target.value = formattedValue;
                                                return field.onChange(e);
                                            }}
                                            classes={{ root: 'custom-select select-multiple-checkbox' }}
                                            input={<Input className="select-multiple-checkbox-input" />}
                                            renderValue={selectedValues => selectedValues.map(platformId => {
                                                const selectedPlatform = platforms.find(({ supplier_id }) => supplier_id === platformId);
                                                return selectedPlatform ? selectedPlatform.name : '';
                                            }).join(', ')}
                                        >
                                            {field.value.map(clientPlatform => {
                                                const platform = platforms.find(({ supplier_id }) => supplier_id === clientPlatform.platform);
                                                return platform ? (
                                                    <MenuItem key={platform.supplier_id} value={platform.supplier_id}>
                                                        <Checkbox checked={clientPlatform.extended_bid} />
                                                        <ListItemText primary={platform.name} />
                                                    </MenuItem>
                                                ) : null;
                                            })}
                                        </Select>
                                    )}
                                </Field>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                        <Grid item xs={2} className="form-item">
                            <span>{t("ClientManagement:userHash")}</span>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="user.user_hash" type="text" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.user && errors.user.user_hash && touched.user && touched.user.user_hash ? <ErrorMessage message={errors.user.user_hash} /> : null}
                            </Grid>
                        </Grid>
                    </Grid>

                    {values.isEdit &&
                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt10">
                        <Grid item xs={2} className="form-item">
                            {t("ClientManagement:updatedDate")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            {values.lastmoddate ? moment(values.lastmoddate).format('YYYY-MM-DD') : ''}

                        </Grid>
                    </Grid>
                    }
                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">{id ? 'Save' : 'Add'}</Button>
                        <Button type="button" className="second-button" onClick={() => { this.cancel() }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    formChangePwdConfiguration = ({ values, errors, touched, handleChange }) => {
        const { t } = this.props;
        return values && (
            <Form id="changePwdForm" className="full-width">
                <Grid container xm={12} alignItems="center">
                    {this.state.ChangePwdMessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={this.state.ChangePwdMessageContent}
                        />
                    </Grid>
                    }

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={3} className="form-item">
                            {t("ClientManagement:password")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Grid item xs={12}>
                                <Field name="new_password" type="password" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                { (errors.new_password && touched.new_password ? <ErrorMessage message={errors.new_password} /> : t("Common:Form.validator.required")) }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={3} className="form-item">
                            {t("ClientManagement:rePassword")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Grid item xs={12}>
                                <Field name="re_new_password" type="password" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.re_new_password && touched.re_new_password ? <ErrorMessage message={errors.re_new_password} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button" onClick={() => { if(!errors.re_new_password){this.handleCloseChangePwd()} }}>Save</Button>
                        <Button type="button" className="second-button" onClick={() => { this.handleCloseChangePwd() }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        const { isEdit, client, openChangePwdDialog } = this.state;
        const inputClient = {...client, isEdit: isEdit};
        const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

        const SchemaUpdate = Yup.object().shape({
            display_name: Yup.string().required("Username is required"),
            email: Yup.string().email("Please input correct email address"),
            wechat_id: Yup.string(),
            phone: Yup.string().matches(phoneRegex, "Phone number is not valid"),
            credit: Yup.string().required("Credit Balance is required").matches(/^[0-9]+\.?[0-9]*$/, "Credit Balance number is not valid"),
            max_credit: Yup.string().required("Max credit is required").matches(/^[0-9]+\.?[0-9]*$/, "Max credit is not valid"),
        });

        const Schema = Yup.object().shape({
            display_name: Yup.string().required("Username is required"),
            email: Yup.string().email("Please input correct email address"),
            wechat_id: Yup.string(),
            phone: Yup.string().matches(phoneRegex, "Phone number is not valid"),
            credit: Yup.string().required("Credit Balance is required").matches(/^[0-9]+\.?[0-9]*$/, "Credit Balance number is not valid"),
            max_credit: Yup.string().required("Max credit is required").matches(/^[0-9]+\.?[0-9]*$/, "Max credit is not valid"),
            password: Yup.string().required("Password is required"),
            re_password: Yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Both password need to be the same"
                )
            }).required("Re-Password is required"),
        });

        const SchemaChangePwd = Yup.object().shape({
            new_password: Yup.string().required("Password is required"),
            re_new_password: Yup.string().when("new_password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("new_password")],
                    "Both password need to be the same"
                )
            }).required("Re-Password is required"),
        })

        return (
            client && (
                <div>
                    <div className="main__container flex-center-item">
                        <Formik
                            enableReinitialize
                            initialValues={inputClient}
                            validationSchema={isEdit ? SchemaUpdate : Schema}
                            onSubmit={this._submitClientForm}
                            component={this.formConfiguration}
                        />

                        <Dialog
                            open={openChangePwdDialog}
                            onClose={this.handleCloseChangePwd}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">SET NEW PASSWORD</DialogTitle>
                            <DialogContent>
                                <Formik
                                    enableReinitialize
                                    initialValues={{new_password: '', re_new_password: '',}}
                                    validationSchema={SchemaChangePwd}
                                    onSubmit={this._submitChangePwdForm}
                                    component={this.formChangePwdConfiguration}
                                />
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>
            )
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientUpdate)));
