// Essential for all components
import React, { Component, Fragment } from 'react';
import moment from "moment";
import get from 'lodash-es/get';
import snakeCase from 'lodash-es/snakeCase';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import Files from "react-files";
import Grid from "@material-ui/core/Grid";
import Input from '@material-ui/core/Input';
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CheckCircle from '@material-ui/icons/CheckCircle';
import BugReportIcon from '@material-ui/icons/BugReportOutlined';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import BaseListPage from "../../components/101BaseListPage/BaseListPage";
import { setBreadcrumb } from "../../Redux/Action/breadcrumbAction";
import { addMessage } from '../../Redux/Action/messageAction';
import { getPlatformKey } from '../../Api/_ApiFactoryWithHeader';
import { apiAuction } from "../../Api/_ApiAuction";
import { apiFile } from '../../Api/_ApiFiles';
import * as apiIndustry from "../../Api/_ApiIndustry";
import { downLoadFile } from '../../utils/Utility';
import { getValAfterSlash } from '../../utils/PaginationUtils';
import AlertDialog from './AlertDialog';

const CheckIcon = () => (
    <CheckCircle style={{ fontSize: 40, color: '#FE6B0B', marginRight: 12 }} />
);

const UncheckIcon = () => (
    <RadioButtonUnchecked style={{ fontSize: 40, color: '#EBEDED', marginRight: 12 }} />
);

const auctionFileRows = [
    { id: 'file_name', numeric: false, label: 'Name', actionLink: true, actionParam: 'file_id' },
    { id: 'lastmoddate', numeric: false, label: 'Update Time', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', width: 180 },
];

const fileRowsPerPageDefault = 10;
const fileOrderByDefault = 'file_name';
const fileOrderDefault = 'asc';

class AuctionUpdate extends Component {

    state = {
        source: '',
        supplier: [],
        sourceValidateMsg: null,
        dateTimeValidate: null,
        isEdit: false,
        alertDialogOpen: false,
        alertDialogType: null,
        loading: false,
        totalLotCount: 0,
        winningLotCount: 0,
        debugEmail: '',
        debugDialogOpen: false,
        auction: {
            source: '',
            start_date: '',
            end_date: '',
            status: '',
            auction_slot_id: '',
            name: '',
            supplier: null,
            start_time: null,
            end_time: null,
            lastmoddate: null,
            selected_start_time: '',
            selected_end_time: ''
        },
        industryType: {
            name: '',
        },
        iconFile: {
            file_url: '',
        },
        files: [],
        fileAmountOfData: '',
        fileCurrentPage: 0,
        fileRowsPerPage: fileRowsPerPageDefault,
        fileOrderBy: fileOrderByDefault,
        fileOrder: fileOrderDefault,
    };

    componentDidMount() {
        const { fileCurrentPage, fileRowsPerPage, fileOrderBy, fileOrder } = this.state;
        if (this.props.id) {
            this.getAuctionDetail();
            this.getAuctionFilesDynamic(fileCurrentPage * fileRowsPerPage, fileRowsPerPage, fileOrderBy, fileOrder, true);
        }
    }

    getAuctionDetail = () => {
        let params = {
            $expand: "supplier,icon_file"
        }
        apiAuction.getAuctionDetail(this.props.id, params).then(obj => {
            if (obj && obj.status === 200) {
                if (obj.data) {
                    const auctionDetail = obj.data;
                    if (auctionDetail.industry_type) {
                        apiIndustry.getDetail(auctionDetail.industry_type).then(rs => {
                            if (rs && rs.status === 200) {
                                if (rs.data) {
                                    this.setState({
                                        industryType: rs.data
                                    });
                                }
                            }
                        });
                    }
                    this.setState({
                        auction: this.prepareData(auctionDetail),
                        source: get(auctionDetail, ['supplier', 'supplier_id'], ''),
                        iconFile: auctionDetail.icon_file,
                    });

                    const userItemBreadcrumb = [{
                        title: `Auction [${auctionDetail.name}]`,
                        link: null
                    }];
                    this.props.setBreadcrumbP({breadcrumbData: userItemBreadcrumb});
                    if (['awarded', 'completed'].includes(auctionDetail.sealed_bid_status)) {
                        this.getLotCount(auctionDetail);
                    }
                }
            } else {
                console.log(obj.error)
            }
        })
    }

    getLotCount = (auction) => {
        const platformId = get(auction, 'supplier.supplier_id');
        const lotParams = {
            'auction_group/auction_slot': this.props.id,
            '$top': 1,
        };
        const winninglotParams = {
            ...lotParams,
            system_account_bidding_status: 'winning',
        };
        Promise.all([
            apiAuction.getLots(getPlatformKey(platformId), lotParams),
            apiAuction.getLots(getPlatformKey(platformId), winninglotParams),
        ])
            .then(([totalRes, winningRes]) => {
            if (totalRes.status === 200 && winningRes.status === 200) {
                const totalCount = getValAfterSlash(totalRes.headers['content-range']);
                const winningCount = getValAfterSlash(winningRes.headers['content-range']);
                this.setState({
                    totalLotCount: totalCount,
                    winningLotCount: winningCount,
                });
            } else {
                if (totalRes.status !== 200) {
                    console.error('totalRes.error', totalRes.error);
                }
                if (winningRes.status !== 200) {
                    console.error('winningRes.error', winningRes.error);
                }
            }
        });
    }

    prepareData = data => {
        if (data) {
            const item = {...data};
            item.update_date = item.lastmoddate ? moment(item.lastmoddate).format('YYYY-MM-DD') : '';
            item.start_date = moment(item.start_time).format('YYYY-MM-DD');
            item.selected_start_time = moment(item.start_time).format('HH:mm');
            item.end_date = moment(item.end_time).format('YYYY-MM-DD');
            item.selected_end_time = moment(item.end_time).format('HH:mm');
            return item;
        }
        return null;
    };

    eCb = (obj) => {
        console.log("eCb : ", obj);
    }

    goToEditPage = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + `/auction/${this.props.id}`);
    }

    back = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/auction-management?previous=true');
    }

    changeAuctionStatus = (status, type = 'status') => {
        const { id, addMessageP } = this.props;
        const body = { type, status };
        this.setState({ loading: true });
        return apiAuction.updateAuctionStatus(id, body)
            .then(res => {
                if (res.status === 200) {
                    this.getAuctionDetail();
                    return;
                } else {
                    const error = res.data && res.data.error ? res.data.error : 'Operation failed';
                    throw new Error(error);
                }
            })
            .catch(err => {
                const msgDsp = {
                    messageSnackbar: err.message,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                addMessageP(msgDsp);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    deleteAuction = () => {
        const { id, addMessageP, history, i18n } = this.props;
        this.setState({ loading: true });
        return apiAuction.deleteAuction(id, null)
            .then(res => {
                if (res.status === 204) {
                    const msgDsp = {
                        messageSnackbar: 'Delete Auction successfully',
                        variantSnackbar: 'success',
                        key: new Date().getTime(),
                    };
                    addMessageP(msgDsp);
                    history.push('/' + i18n.language + '/auction-management?previous=true')
                    return;
                } else {
                    const error = res.data && res.data.error ? res.data.error : 'Delete Auction failed';
                    throw new Error(error);
                }
            })
            .catch(err => {
                const msgDsp = {
                    messageSnackbar: err.message,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                addMessageP(msgDsp);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    submitSealedBidInfo = (debug, recipient) => {
        const { id, addMessageP } = this.props;
        const { auction } = this.state;
        const platformId = get(auction, 'supplier.supplier_id');
        this.setState({ loading: true });
        const body = { debug, recipient };
        return apiAuction.submitSealedBid(getPlatformKey(platformId), id, body)
            .then(res => {
                if (res.status === 200) {
                    const msgDsp = {
                        messageSnackbar: 'Submit Bid Info success',
                        variantSnackbar: 'success',
                        key: new Date().getTime(),
                    };
                    this.getAuctionDetail();
                    addMessageP(msgDsp);
                    if (debug) {
                        this.setState({ debugDialogOpen: false, debugEmail: '' });
                    }
                } else {
                    const error = res.data && res.data.error ? res.data.error : 'Submit Bid Info failed';
                    throw new Error(error);
                }
            })
            .catch(err => {
                const msgDsp = {
                    messageSnackbar: err.message,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                addMessageP(msgDsp);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    downloadAuctionInfo = () => {
        const { id, addMessageP } = this.props;
        const { auction } = this.state;
        const platformId = get(auction, 'supplier.supplier_id');
        this.setState({ loading: true });
        return apiAuction.downloadAuctionInfo(getPlatformKey(platformId), id, {})
            .then(res => {
                if (res.status === 200) {
                    const fileName = `auction_info_${snakeCase(auction.name)}_${moment().format('YYYYMMDD')}`;
                    return downLoadFile(res.data, fileName);
                } else {
                    const error = res.data && res.data.error ? res.data.error : 'Download Auction Info failed';
                    throw new Error(error);
                }
            })
            .catch(err => {
                const msgDsp = {
                    messageSnackbar: err.message,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                addMessageP(msgDsp);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    downloadSealedBidInfo = () => {
        const { id, addMessageP } = this.props;
        const { auction } = this.state;
        const platformId = get(auction, 'supplier.supplier_id');
        this.setState({ loading: true });
        return apiAuction.downloadSealedBidInfo(getPlatformKey(platformId), id, {})
            .then(res => {
                if (res.status === 200) {
                    const fileName = `bid_info_${snakeCase(auction.name)}_${moment().format('YYYYMMDD')}`;
                    return downLoadFile(res.data, fileName);
                } else {
                    const error = res.data && res.data.error ? res.data.error : 'Download Bid Info failed';
                    throw new Error(error);
                }
            })
            .catch(err => {
                const msgDsp = {
                    messageSnackbar: err.message,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                addMessageP(msgDsp);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    uploadAuctionInfo = (files) => {
        const { id, addMessageP } = this.props;
        const { auction } = this.state;
        const platformId = get(auction, 'supplier.supplier_id');
        if (files && files.length > 0) {
            const file = files[files.length - 1];
            const reader = new FileReader();
            this.setState({ loading: true });
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                return apiAuction.uploadAuctionInfo(getPlatformKey(platformId), id, { file })
                    .then(res => {
                        if (res.status === 200) {
                            const msgDsp = {
                                messageSnackbar: 'Upload Auction Info success',
                                variantSnackbar: 'success',
                                key: new Date().getTime(),
                            };
                            addMessageP(msgDsp);
                        } else {
                            const error = res.data && res.data.error ? res.data.error : 'Upload Auction Info failed';
                            throw new Error(error);
                        }
                    })
                    .catch(err => {
                        const msgDsp = {
                            messageSnackbar: err.message,
                            variantSnackbar: 'error',
                            key: new Date().getTime(),
                        };
                        addMessageP(msgDsp);
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });
            };
        }
    }

    uploadSealedBidInfo = (files) => {
        const { id, addMessageP } = this.props;
        const { auction } = this.state;
        const platformId = get(auction, 'supplier.supplier_id');
        if (files && files.length > 0) {
            const file = files[files.length - 1];
            const reader = new FileReader();
            this.setState({ loading: true });
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                return apiAuction.uploadSealedBidInfo(getPlatformKey(platformId), id, { file })
                    .then(res => {
                        if (res.status === 200) {
                            const msgDsp = {
                                messageSnackbar: 'Upload Bid Info success',
                                variantSnackbar: 'success',
                                key: new Date().getTime(),
                            };
                            addMessageP(msgDsp);
                        } else {
                            const error = res.data && res.data.error ? res.data.error : 'Upload Bid Info failed';
                            throw new Error(error);
                        }
                    })
                    .catch(err => {
                        const msgDsp = {
                            messageSnackbar: err.message,
                            variantSnackbar: 'error',
                            key: new Date().getTime(),
                        };
                        addMessageP(msgDsp);
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });
            };
        }
    }

    onUploadFileError = (error) => {
        const { addMessageP } = this.props;
        const msgDsp = {
            messageSnackbar: error.message,
            variantSnackbar: 'error',
            key: new Date().getTime(),
        };
        addMessageP(msgDsp);
    }

    downloadSealedAwardInfo = () => {
        const { id, addMessageP } = this.props;
        const { auction } = this.state;
        const platformId = get(auction, 'supplier.supplier_id');
        this.setState({ loading: true });
        return apiAuction.downloadSealedBidAwardInfo(getPlatformKey(platformId), id, {})
            .then(res => {
                if (res.status === 200) {
                    const fileName = `award_info_${snakeCase(auction.name)}_${moment().format('YYYYMMDD')}`;
                    return downLoadFile(res.data, fileName);
                } else {
                    const error = res.data && res.data.error ? res.data.error : 'Download Award failed';
                    throw new Error(error);
                }
            })
            .catch(err => {
                const msgDsp = {
                    messageSnackbar: err.message,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                addMessageP(msgDsp);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    uploadSealedAwardInfo = (files) => {
        const { id, addMessageP } = this.props;
        const { auction } = this.state;
        const platformId = get(auction, 'supplier.supplier_id');
        if (files && files.length > 0) {
            const file = files[files.length - 1];
            const reader = new FileReader();
            this.setState({ loading: true });
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                return apiAuction.uploadSealedBidInfo(getPlatformKey(platformId), id, { file })
                    .then(res => {
                        if (res.status === 200) {
                            const msgDsp = {
                                messageSnackbar: 'Upload Award Info success',
                                variantSnackbar: 'success',
                                key: new Date().getTime(),
                            };
                            addMessageP(msgDsp);
                        } else {
                            const error = res.data && res.data.error ? res.data.error : 'Upload Award Info failed';
                            throw new Error(error);
                        }
                    })
                    .catch(err => {
                        const msgDsp = {
                            messageSnackbar: err.message,
                            variantSnackbar: 'error',
                            key: new Date().getTime(),
                        };
                        addMessageP(msgDsp);
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });
            };
        }
    }

    sendSealedAwardInfo = () => {
        const { id, addMessageP } = this.props;
        const { auction } = this.state;
        const platformId = get(auction, 'supplier.supplier_id');
        this.setState({ loading: true });
        return apiAuction.completeSealedBidAward(getPlatformKey(platformId), id, {})
            .then(res => {
                if (res.status === 200) {
                    const msgDsp = {
                        messageSnackbar: 'Send Award Info success',
                        variantSnackbar: 'success',
                        key: new Date().getTime(),
                    };
                    this.getAuctionDetail();
                    addMessageP(msgDsp);
                } else {
                    const error = res.data && res.data.error ? res.data.error : 'Send Award Info failed';
                    throw new Error(error);
                }
            })
            .catch(err => {
                const msgDsp = {
                    messageSnackbar: err.message,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                addMessageP(msgDsp);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    getAuctionFilesDynamic = (skipAmount, rowsPerPage, orderBy, order, isResetNavAndDataAmount) => {
        const { id, addMessageP } = this.props;
        const params = {
            auction_slot: id,
            '$orderby': `${orderBy} ${order}`,
            '$top': rowsPerPage,
            '$skip': skipAmount,
        };
        return apiFile.getFiles(params).then(res => {
            if (res && res.status === 200) {
                if (isResetNavAndDataAmount) {
                    const dataSum = getValAfterSlash(res.headers['content-range']);
                    this.setState({ fileAmountOfData: dataSum });
                }
                this.setState({ files: res.data });
            } else {
                const error = res.data && res.data.error ? res.data.error : 'Failed to get auction files';
                throw new Error(error);
            }
        }).catch(err => {
            const msgDsp = {
                messageSnackbar: err.message,
                variantSnackbar: 'error',
                key: new Date().getTime(),
            };
            addMessageP(msgDsp);
        });
    }

    uploadAuctionFile = (file , rowsPerPage) => {
        const { id, addMessageP } = this.props;
        const { fileCurrentPage, fileOrderBy, fileOrder } = this.state;
        const body = {
            ...file,
            auction_slot: id,
        };
        this.setState({ loading: true });
        return apiFile.createFile(body).then(res => {
            if (res && res.status === 201) {
                const msgDsp = {
                    messageSnackbar: 'Upload Auction File success',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                addMessageP(msgDsp);
                this.getAuctionFilesDynamic(fileCurrentPage * rowsPerPage, rowsPerPage, fileOrderBy, fileOrder, true);
            } else {
                const error = res.data && res.data.error ? res.data.error : 'Upload failed';
                throw new Error(error);
            }
        }).catch(err => {
            const msgDsp = {
                messageSnackbar: err.message,
                variantSnackbar: 'error',
                key: new Date().getTime(),
            };
            addMessageP(msgDsp);
        })
        .finally(() => {
            this.setState({ loading: false });
        });
    }

    editAuctionFile = (fileId) => {
        const { id, history, i18n } = this.props;
        history.push('/' + i18n.language + '/auction-management/' + id + '/files/' + get(fileId, 0));
    }

    deleteAuctionFile = (fileIds, rowsPerPage) => {
        const { addMessageP } = this.props;
        const { fileCurrentPage, fileOrderBy, fileOrder } = this.state;
        this.setState({ loading: true });
        return apiFile.deleteFiles(fileIds).then(res => {
            if (res && res.status === 204) {
                const msgDsp = {
                    messageSnackbar: 'Delete Auction File(s) success',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                addMessageP(msgDsp);
                this.getAuctionFilesDynamic(fileCurrentPage * rowsPerPage, rowsPerPage, fileOrderBy, fileOrder, true);
            } else {
                const error = res.data && res.data.error ? res.data.error : 'Failed to delete Auction File(s)';
                throw new Error(error);
            }
        }).catch(err => {
            const msgDsp = {
                messageSnackbar: err.message,
                variantSnackbar: 'error',
                key: new Date().getTime(),
            };
            addMessageP(msgDsp);
        })
        .finally(() => {
            this.setState({ loading: false });
        });
    }

    onClickDownloadFile = (fileId) => {
        const { files } = this.state;
        const file = files.find(({ file_id }) => file_id === fileId);
        return fetch(get(file, 'file_url'))
            .then(res => res.blob())
            .then(blobData => {
                downLoadFile(blobData, get(file, 'file_name'));
            });
    }

    render() {
        const { t } = this.props;
        const { auction, industryType, iconFile, totalLotCount, winningLotCount, files, fileAmountOfData, fileCurrentPage, fileOrder, fileOrderBy, fileRowsPerPage, alertDialogOpen, alertDialogType, debugDialogOpen, debugEmail, loading } = this.state;
        const fileUrl = get(iconFile, 'file_url');
        return (
            auction && (
                <div>
                    <div className="main__container flex-center-item">
                        <Grid container xm={12} alignItems="center">
                            <Grid container xm={12} direction="row" alignItems="flex-start">
                                <Grid item xs={12} md={6} className="button-wrapper form-item">
                                    <Button
                                        type="button"
                                        className="primary-button m0"
                                        onClick={() => this.goToEditPage()}
                                        disabled={['stopped', 'ended'].includes(auction.status) || moment().isAfter(moment(auction.start_time))}
                                    >
                                        {t("Common:Button.edit")} Auction
                                    </Button>
                                    <Button
                                        onClick={() => this.changeAuctionStatus(auction.status === 'paused' ? 'new' : 'paused')}
                                        style={{ width: 'auto', margin: 0, marginLeft: 20 }}
                                        disabled={['stopped', 'ended'].includes(auction.status) || moment().isBefore(moment(auction.start_time))}
                                    >
                                        {auction.status === 'paused' ? 'Resume' : 'Pause'} Auction
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6} className="button-wrapper form-item flex-end-item">
                                    {(['new', null].includes(auction.status) && moment().isBefore(moment(auction.start_time))) ? (
                                        <Button
                                            type="button"
                                            className="delete-button"
                                            style={{ width: 'auto' }}
                                            onClick={() => this.setState({
                                                alertDialogOpen: true,
                                                alertDialogType: 'delete',
                                            })}
                                        >
                                            {t("Common:Button.delete")} Auction
                                        </Button>
                                    ) : (
                                        <Button
                                            type="button"
                                            className="stop-button"
                                            disabled={['stopped', 'ended'].includes(auction.status) || moment().isAfter(moment(auction.end_time))}
                                            onClick={() => this.setState({
                                                alertDialogOpen: true,
                                                alertDialogType: 'stop',
                                            })}
                                        >
                                            Stop Auction
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                                <Grid item xs={3} className="form-item">
                                    {t("AuctionManagement:auctionName")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {auction.status === 'stopped' ? (
                                        <span style={{ color: '#FF0000' }}>
                                            {auction.name} (Stopped)
                                        </span>
                                    ) : `${auction.name}${auction.status === 'paused' ? ' (Paused)' : ''}`}
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                                <Grid item xs={3} className="form-item">
                                    {t("AuctionManagement:source")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {auction.supplier ? auction.supplier.name : ''}
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                                <Grid item xs={3} className="form-item">
                                    {t("AuctionManagement:startTime")}
                                </Grid>
                                <Grid item xs={9} className="form-row">
                                    {auction.start_date} {auction.selected_start_time}
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                                <Grid item xs={3} className="form-item">
                                    {t("AuctionManagement:endTime")}
                                </Grid>
                                <Grid item xs={9} className="form-row">
                                    {auction.end_date} {auction.selected_end_time}
                                </Grid>
                            </Grid>

                            {(get(auction.supplier, 'type') || '').includes('self_hosted') && (
                                <Fragment>
                                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                                        <Grid item xs={3} className="form-item">
                                            {t("AuctionManagement:industryType")}
                                        </Grid>
                                        <Grid item xs={9} className="form-row">
                                            {get(industryType, 'name')}
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                                        <Grid item xs={3} className="form-item">
                                            {t("AuctionManagement:iconFile")}
                                        </Grid>
                                        <Grid item xs={9} className="form-row">
                                            {fileUrl && <img
                                                src={fileUrl}
                                                style={{
                                                    maxHeight: '250px',
                                                    maxWidth: '250px',
                                                }}
                                            />}
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}

                            {(get(auction, 'supplier.bid_type') || '').includes('sealed') && (
                                <>
                                    {!(get(auction, 'supplier.type') || '').includes('self_hosted') &&
                                        <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                                            <Grid item xs={3} className="form-item">
                                                {t("AuctionManagement:autoSendBidInfo")}
                                            </Grid>
                                            <Grid item xs={9} className="form-row">
                                                {auction.auto_send_sealed_bid ? 'Yes' : 'No'}
                                            </Grid>
                                        </Grid>
                                    }

                                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                                        <Grid item xs={3} className="form-item">
                                            {t("AuctionManagement:autoSendAwardInfo")}
                                        </Grid>
                                        <Grid item xs={9} className="form-row">
                                            {auction.auto_send_award ? 'Yes' : 'No'}
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            {!(get(auction, 'supplier.bid_type') || '').includes('sealed') && (
                                (get(auction, ['supplier', 'type']) || '').includes('self_hosted') ||
                                (get(auction, ['supplier', 'name']) || '').includes('Self-hosted')
                            ) && (
                                <Grid container className="pt40">
                                    <Grid item container xs={6} className="button-wrapper form-item">
                                        <Button
                                            className="primary-button"
                                            style={{ margin: 0, minWidth: 180 }}
                                            onClick={this.downloadAuctionInfo}
                                        >
                                            Download Auction Info
                                        </Button>
                                        <Files
                                            onChange={auction.status !== 'ended' ? this.uploadAuctionInfo : () => {}}
                                            onError={this.onUploadFileError}
                                            accepts={['.xlsx']}
                                            maxFileSize={10000000}
                                            minFileSize={0}
                                            multiple={false}
                                            clickable={auction.status !== 'ended'}
                                            style={{ marginLeft: 12 }}
                                        >
                                            <Button
                                                className="primary-button"
                                                disabled={auction.status === 'ended'}
                                                style={{ margin: 0, minWidth: 180 }}
                                            >
                                                Upload Auction Info
                                            </Button>
                                        </Files>
                                    </Grid>
                                </Grid>
                            )}
                            {(get(auction, 'supplier.bid_type') || '').includes('sealed') && (
                                <Grid container className="pt40">
                                    <h4>Auction Stage:</h4>
                                    <Grid container direction="row" alignItems="center" className="pt10">
                                        <Grid item container xs={6} className="form-item">
                                            {auction.sealed_bid_status !== 'open' || moment().isAfter(moment(auction.start_time)) ? <CheckIcon /> : <UncheckIcon />}
                                            Online Bid Begin
                                        </Grid>
                                        {(get(auction, ['supplier', 'type']).includes('self_hosted') || get(auction, ['supplier', 'name']).includes('Self-hosted')) && (
                                            <Fragment>
                                                <Grid item container xs={6} className="button-wrapper form-item">
                                                    <Button
                                                        className="primary-button"
                                                        style={{ margin: 0, minWidth: 180 }}
                                                        onClick={this.downloadAuctionInfo}
                                                    >
                                                        Download Auction Info
                                                    </Button>
                                                    <Files
                                                        onChange={auction.sealed_bid_status === 'open' ? this.uploadAuctionInfo : () => {}}
                                                        onError={this.onUploadFileError}
                                                        accepts={['.xlsx']}
                                                        maxFileSize={10000000}
                                                        minFileSize={0}
                                                        multiple={false}
                                                        clickable={auction.sealed_bid_status === 'open'}
                                                        style={{ marginLeft: 12 }}
                                                    >
                                                        <Button
                                                            className="primary-button"
                                                            disabled={auction.sealed_bid_status !== 'open'}
                                                            style={{ margin: 0, minWidth: 180 }}
                                                        >
                                                            Upload Auction Info
                                                        </Button>
                                                    </Files>
                                                </Grid>
                                            </Fragment>
                                        )}
                                    </Grid>
                                    {!(get(auction, ['supplier', 'type']) || '').includes('self_hosted') &&
                                    !(get(auction, ['supplier', 'name']) || '').includes('Self-hosted') &&
                                    <Fragment>
                                        <Grid container direction="row" alignItems="center" className="pt10">
                                            <Grid item container xs={6} className="form-item" alignItems="center">
                                                {auction.sealed_bid_status !== 'open' ? <CheckIcon /> : <UncheckIcon />}
                                                Online Bid End and Download Bid Info
                                            </Grid>
                                            <Grid item xs={6} className="button-wrapper form-item">
                                                <Button
                                                    className="primary-button"
                                                    onClick={this.downloadSealedBidInfo}
                                                    disabled={!['closed', 'submitted'].includes(auction.sealed_bid_status)}
                                                    style={{ margin: 0, minWidth: 180 }}
                                                >
                                                    Download Bid Info
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" alignItems="center" className="pt10">
                                            <Grid item container xs={6} className="form-item">
                                                {auction.sealed_bid_status !== 'open' ? <CheckIcon /> : <UncheckIcon />}
                                                Upload and Submit Bid Info to Auction owner
                                            </Grid>
                                            <Grid item container xs={6} className="button-wrapper form-item">
                                                <Files
                                                    onChange={auction.sealed_bid_status === 'closed' ? this.uploadSealedBidInfo : () => {}}
                                                    onError={this.onUploadFileError}
                                                    accepts={['.csv']}
                                                    maxFileSize={10000000}
                                                    minFileSize={0}
                                                    multiple={false}
                                                    clickable={auction.sealed_bid_status === 'closed'}
                                                >
                                                    <Button
                                                        className="primary-button"
                                                        disabled={auction.sealed_bid_status !== 'closed'}
                                                        style={{ margin: 0, minWidth: 180 }}
                                                    >
                                                        Upload Bid Info
                                                    </Button>
                                                </Files>
                                                <Button
                                                    className="send-button"
                                                    onClick={() => this.submitSealedBidInfo()}
                                                    disabled={auction.sealed_bid_status !== 'closed'}
                                                    style={{ margin: 0, minWidth: 180, marginLeft: 12 }}
                                                >
                                                    Submit Bid Info
                                                </Button>
                                                {get(auction, 'supplier.bid_type') === 'sealed_email' && (
                                                    <Button
                                                        className="stop-button"
                                                        onClick={() => this.setState({ debugDialogOpen: true })}
                                                        disabled={auction.sealed_bid_status !== 'closed'}
                                                        style={{ margin: 0, minWidth: 'auto', width: 'auto', marginLeft: 12 }}
                                                    >
                                                        <BugReportIcon /> Debug
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {get(auction, 'supplier.bid_type') === 'sealed_online' && (
                                            <Grid container direction="row" alignItems="center" className="pt10">
                                                <Grid item container xs={6} className="form-item">
                                                    {!['open', 'closed'].includes(auction.sealed_bid_status) ? <CheckIcon /> : <UncheckIcon />}
                                                    Wating for Auction to be awarded
                                                </Grid>
                                                <Grid item xs={6} className="button-wrapper form-item">
                                                    <Button
                                                        className="primary-button"
                                                        onClick={() => this.changeAuctionStatus('waiting_award', 'sealed_bid_status')}
                                                        disabled={auction.sealed_bid_status !== 'submitted'}
                                                        style={{ margin: 0, minWidth: 180 }}
                                                    >
                                                        Mark as Ready
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Fragment>}
                                    <Grid container direction="row" alignItems="center" className="pt10">
                                        <Grid item container xs={6} className="form-item">
                                            {['awarded', 'completed'].includes(auction.sealed_bid_status) ? <CheckIcon /> : <UncheckIcon />}
                                            Auction End and Download Award Info
                                        </Grid>
                                        <Grid item xs={6} className="button-wrapper form-item">
                                            <Button
                                                className="primary-button"
                                                onClick={this.downloadSealedAwardInfo}
                                                disabled={!['awarded', 'completed'].includes(auction.sealed_bid_status)}
                                                style={{ margin: 0, minWidth: 180 }}
                                            >
                                                Download Award Info
                                            </Button>
                                            {['awarded', 'completed'].includes(auction.sealed_bid_status) && (
                                                <span style={{ marginLeft: 12 }}>
                                                    Award Item(s): {winningLotCount || 0} / {totalLotCount || 0}
                                                </span>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" alignItems="center" className="pt10">
                                        <Grid item container xs={6} className="form-item">
                                            {['awarded', 'completed'].includes(auction.sealed_bid_status) ? <CheckIcon /> : <UncheckIcon />}
                                            Upload and Send Award to eBid users
                                        </Grid>
                                        <Grid item container xs={6} className="button-wrapper form-item">
                                            <Files
                                                onChange={auction.sealed_bid_status === 'awarded' ? this.uploadSealedAwardInfo : () => {}}
                                                onError={this.onUploadFileError}
                                                accepts={['.csv']}
                                                maxFileSize={10000000}
                                                minFileSize={0}
                                                multiple={false}
                                                clickable={auction.sealed_bid_status === 'awarded'}
                                            >
                                                <Button
                                                    className="primary-button"
                                                    disabled={auction.sealed_bid_status !== 'awarded'}
                                                    style={{ margin: 0, minWidth: 180 }}
                                                >
                                                    Upload Award Info
                                                </Button>
                                            </Files>
                                            <Button
                                                className="send-button"
                                                onClick={this.sendSealedAwardInfo}
                                                disabled={auction.sealed_bid_status !== 'awarded'}
                                                style={{ margin: 0, minWidth: 180, marginLeft: 12 }}
                                            >
                                                Send Award Info
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12} className="pt40">
                                <h4>Auction Files:</h4>
                                <BaseListPage
                                    pageId="auction-files"
                                    mainClass="auction-files"
                                    primaryKey="file_id"
                                    displayActionBar
                                    updateButton
                                    updateButtonText="Edit File"
                                    updateButtonAction={this.editAuctionFile}
                                    deleteButton
                                    deleteButtonText="Delete File"
                                    deleteButtonAction={this.deleteAuctionFile}
                                    importButton
                                    importButtonText="Upload File"
                                    importButtonAction={this.uploadAuctionFile}
                                    getDataDynamic={this.getAuctionFilesDynamic}
                                    clickToFunction={this.onClickDownloadFile}
                                    currentPage={fileCurrentPage}
                                    rows={auctionFileRows}
                                    data={files}
                                    displaySelectBox
                                    orderDefault={fileOrder}
                                    orderByDefault={fileOrderBy}
                                    rowsPerPageDefault={fileRowsPerPage}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    amountOfData={fileAmountOfData}
                                />
                            </Grid>
                            <Grid item xs={12} className="button-wrapper form-item pt20">
                                <Button type="button" className="back-button" onClick={() => {
                                    this.back()
                                }}>Back</Button>
                            </Grid>
                        </Grid>
                        <AlertDialog
                            isOpen={alertDialogOpen}
                            title="WARNING"
                            onAction={() => {
                                if (alertDialogType === 'stop') {
                                    this.changeAuctionStatus('stopped');
                                } else {
                                    this.deleteAuction();
                                }
                                this.setState({ alertDialogOpen: false });
                            }}
                            onCancel={() => this.setState({ alertDialogOpen: false })}
                            actionButtonText={alertDialogType === 'stop' ? 'Stop' : 'Delete'}
                            cancelButtonText="No"
                            actionButtonClass={alertDialogType === 'stop' ? 'stop-button' : 'delete-button'}
                        >
                            Are youa sure you want to {alertDialogType === 'stop' ? 'Stop' : 'Delete'} '{auction.name}'?<br />
                            You can't undo this.
                        </AlertDialog>
                        <Dialog open={debugDialogOpen} onBackdropClick={() => this.setState({ debugDialogOpen: false })} fullWidth>
                            <DialogContent>
                                <DialogContentText color="initial">Enter the testing email:</DialogContentText>
                                <Input value={debugEmail} onChange={(e) => this.setState({ debugEmail: e.target.value })} fullWidth />
                            </DialogContent>
                            <DialogActions style={{ padding: 24, paddingTop: 8 }}>
                                <Grid className="button-wrapper form-item" container justify="space-between">
                                    <Button
                                        type="button"
                                        className="back-button"
                                        onClick={() => this.setState({ debugDialogOpen: false })}
                                        style={{ margin: 0 }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="button"
                                        className="stop-button"
                                        onClick={() => this.submitSealedBidInfo(true, debugEmail)}
                                        disabled={!debugEmail || loading}
                                        style={{ margin: 0 }}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                        {loading && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            }} />
                        )}
                    </div>
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(AuctionUpdate)));
